var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"type-selector"},[_vm._l((_vm.ptypeList),function(type){return _c('button',{key:type,class:{
			['type-button']: true,
			[`bg-color-ptype-${type.toLowerCase()}`]: true,
			selected: _vm.value == type,
		},on:{"click":function($event){return _vm.$emit('input', type)}}},[_vm._v(" "+_vm._s(_vm.toTitleCase(type))+" ")])}),(_vm.allowNone)?_c('button',{class:{
			'type-button': true,
			selected: _vm.value == null,
			'none-button': true,
		},on:{"click":function($event){return _vm.$emit('input', null)}}},[_vm._v(" (None) ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }