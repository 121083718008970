<template>
	<matchup-chart/>
</template>

<script>
import MatchupChart from "@/components/MatchupChart.vue";
import Vue from "vue";


export default Vue.extend({
	components: {
		MatchupChart,
	},
});
</script>

<style scoped lang="scss">

</style>
