var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
		result: true,
		zero: _vm.result == 0,
		half: _vm.result == .5,
		basic: _vm.result == 1,
		double: _vm.result == 2,
	}},[(_vm.result == 0)?[_vm._v(" 0 ")]:(_vm.result == .5)?[_vm._v(" ½ ")]:(_vm.result == 2)?[_vm._v(" 2 ")]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }