var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chart-container"},[_c('table',{class:{
			chart: true,
			'has-highlight': _vm.highlightedAttacker || _vm.highlightedDefender,
		}},[_c('thead',[_c('tr',[_vm._m(0),_vm._l((_vm.types),function(defenderType){return _c('th',{key:defenderType,staticClass:"defender-header",on:{"mouseenter":function($event){return _vm.hoverType(
						defenderType,
						'defender',
					)},"mouseleave":function($event){return _vm.hoverType(null, 'defender')},"click":function($event){return _vm.clickType(defenderType, 'defender')}}},[_c('matchup-type-block',{class:{
							'defender-type': true,
							highlighted: (
								defenderType == _vm.highlightedDefender
							),
						},attrs:{"type":defenderType}})],1)})],2)]),_c('tbody',_vm._l((_vm.types),function(attackerType){return _c('tr',{key:attackerType},[_c('th',{staticClass:"attacker-header",attrs:{"scope":"row"},on:{"mouseenter":function($event){return _vm.hoverType(attackerType, 'attacker')},"mouseleave":function($event){return _vm.hoverType(null, 'attacker')},"click":function($event){return _vm.clickType(attackerType, 'attacker')}}},[_c('matchup-type-block',{class:{
							'attacker-type': true,
							highlighted: (
								attackerType == _vm.highlightedAttacker
							),
						},attrs:{"type":attackerType}})],1),_vm._l((_vm.types),function(defenderType){return _c('td',{key:defenderType},[_c('matchup-chart-result',{class:{
							highlighted: (
								attackerType == _vm.highlightedAttacker
								|| defenderType == _vm.highlightedDefender
							),
						},attrs:{"attacking-type":attackerType,"defending-type":defenderType}})],1)})],2)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"corner-header"},[_c('div',[_c('div',{staticClass:"background-triangle"}),_c('span',{staticClass:"attacker"},[_vm._v("Attacker")]),_c('span',{staticClass:"defender"},[_vm._v("Defender")])])])
}]

export { render, staticRenderFns }